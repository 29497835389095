module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"brighton-repairs-website","short_name":"brighton-repairs","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","theme_color_in_head":false,"display":"minimal-ui","icon":"/opt/build/repo/node_modules/@servicesite/gatsby-theme-appliance-repair/src/images/favicon.png","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42eed66102219cb24b133ee62605e6ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@servicesite/gatsby-theme-appliance-repair/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brighton Repairs","title":"Brighton Repairs - Appliance Repairs in Brighton and surrounding areas!","area":"Brighton","googleMapsApiKey":"AIzaSyBgkCBaNIJuXJp3gXgLQKNI9amatChArn0","mapZoomLevel":9,"googleTagManagerId":0},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
